@import "../config"
@import "/styles/config"
.error
	&Wrapper
		max-height: 240px
		padding-right: 10px
		font: normal 18px / 100% $font

	&Label
		margin-bottom: 20px
		color: #F00F

	&Text
		overflow: auto
		width: 600px
		white-space: pre-wrap
